<template>
  <section v-if="activeChannel && activeSubject">
    <div class="row">
      <div class="col-sm-3">
        <div class="nav-tabs-custom">
          <ul class="nav nav-tabs">
            <li class="header">
              <span
                class="clicable"
                v-if="enabled !== undefined && $attrs.canDisable"
                @click.stop.prevent="$emit(enabled ? 'disable' : 'enable')"
              >
                <i
                  :class="enabled ? 'fa fa-check-square-o' : 'fa fa-square-o'"
                ></i>
                {{ $tc(this.title, 2) }}
              </span>
              <span v-else>
                <i class="fa fa-send"></i> {{ $tc(this.title, 2) }}
              </span>
              <Tooltip :title="$t(title_tooltip)" style="font-size: 80%" />
            </li>
          </ul>
        </div>
      </div>
      <div class="col-sm-9" v-if="enabled === undefined || enabled">
        <div class="nav-tabs-custom">
          <ul class="nav nav-tabs" data-testid="notification-types">
            <li
              v-for="item in subjectList"
              :class="item.name == subject ? 'active' : ''"
              :key="item.name"
              :data-testid="item.name"
              @click="subject = item.name"
            >
              <a :href="`#${item.name}`" data-toggle="tab" @click="$emit('tabSelected', item.name)">
                <i :class="item.icon"></i>
                {{ $tc(item.title, 1) }}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row" v-if="enabled === undefined || enabled">
      <div class="col-sm-3" v-if="!menuCollapsed">
        <template v-if="notification_mode != undefined && $attrs.canDisable">
          <div class="padding-10 bg-whitesmoke">
            <label for="notification_mode">
              {{ $t("titles.notification_mode") }}
              <Tooltip :title="$t('hints.notification_mode')" />
            </label>
            <select
              class="filter-option form-control"
              v-model="notification_mode_value"
              id="notification_mode"
            >
              <option
                :value="item.id"
                :key="item.id"
                v-for="item in notification_mode_list"
              >
                {{ item.description }}
              </option>
            </select>
          </div>
        </template>
        <div
          class="box box-solid"
          :class="menuCollapsed ? 'collapsed-box' : ''"
        >
          <div class="box-header with-border">
            <h3 class="box-title">
              {{ $tc("channel", 2) }}
            </h3>
            <div class="box-tools">
              <button
                type="button"
                class="btn btn-box-tool"
                data-widget="collapse"
                @click.stop.prevent="menuCollapsed = !menuCollapsed"
              >
                <i :class="menuCollapsed ? 'fa fa-plus' : 'fa fa-minus'"></i>
              </button>
            </div>
          </div>
          <div class="box-body no-padding">
            <ul class="nav nav-pills nav-stacked" data-testid="channels">
              <template v-for="item in filteredChannelList">
                <li
                  :key="item.name"
                  :class="channel == item.name ? 'active' : ''"
                  :data-testid="item.name"
                  @click.stop.prevent="channel = item.name"
                  v-if="can(item.name)"
                >
                  <a href=""><i :class="item.icon"></i> {{ $t(item.name) }}</a>
                </li>
              </template>
            </ul>
          </div>
        </div>
      </div>
      <div :class="menuCollapsed ? 'col-sm-12' : 'col-sm-9'">
        <div class="box box-solid">
          <div class="box-header with-border" style="padding-left: 0">
            <h3 class="box-title">
              <span
                v-if="this.filteredChannelList.length > 1"
                class="clicable menu-collapse"
                @click.stop.prevent="menuCollapsed = !menuCollapsed"
              >
                <i :class="activeChannel.icon"></i>
                {{ $t("users_to_notify") }}
              </span>
              <span v-else>
                <i :class="activeChannel.icon"></i>
                {{ $t("users_to_notify") }}
              </span>
              <Tooltip :title="$t(activeSubject.text)" />
            </h3>
          </div>
          <div class="box-body no-padding">
            <div class="tab-content">
              <div
                class="tab-pane"
                v-for="item in subjectList"
                :class="item.name == subject ? 'active' : ''"
                :key="item.name"
                :id="item.name"
              >
                <div class="tag-input">
                  <vue-tags-input
                    v-if="group"
                    data-testid="notification-form"
                    v-model="tag"
                    :placeholder="group.placeholder"
                    :tags="tags"
                    :allow-edit-tags="true"
                    :add-from-paste="false"
                    :add-on-key="[13, ':', ';']"
                    :validation="tag_validation"
                    @tags-changed="(newTags) => (tags = newTags)"
                    ref="inp"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { isEqual } from "lodash";
import VueTagsInput from "@johmun/vue-tags-input";
import Tooltip from "@/components/tooltip.vue";
import messages from "@/i18n/notifications.js";
export default {
  name: "FormConfigNotification",
  i18n: {
    messages
  },
  components: {
    VueTagsInput,
    Tooltip
  },
  props: {
    value: {
      type: Array,
      required: false,
      default: () => []
    },
    notification_channels: {
      type: Array,
      required: false,
      default: () => []
    },
    enabled: {
      type: Boolean,
      required: false,
      default: undefined
    },
    notification_mode: {
      type: Number,
      required: false,
      default: undefined
    },
    title: {
      type: String,
      default: "notification",
      required: false,
    },
    title_tooltip: {
      type: String,
      default: "hints.notification_enabled",
      required: false,
    },
    defaults: {
      type: Object,
      default: () => ({ channel: "email", subject: "default_notifications" }),
      required: false,
    },
  },
  data() {
    return {
      tag: "",
      group: null,
      channel: this.defaults.channel,
      subject: this.defaults.subject,
      menuCollapsed: false,
      notification_mode_value: undefined,
    };
  },
  computed: {
    contract() {
      return this.$store.getters["user/contract"];
    },
    config() {
      return this?.notification_channels?.length
        ? this.notification_channels
        : this.$root.config.notification_channels;
    },
    channelList() {
      return this.config
        .filter((i) => i.enabled)
        .map((i) => ({ name: i.channel, icon: i.icon }));
    },
    filteredChannelList() {
      return (this.channelList || []).filter(({ name }) => this.can(name));
    },
    subjectList() {
      if (this.activeChannel) {
        let channel = this.config.find(
          (i) => i.channel == this.activeChannel.name
        );
        if (channel && channel.data_source) {
          return Object.keys(channel.data_source || {}).map((k) => ({
            title: channel.data_source[k].short,
            name: k,
            icon: channel.data_source[k].icon,
            text: channel.data_source[k].text
          }));
        }
      }
      return [];
    },
    selection() {
      return `${this?.activeChannel?.name || ""}-${this?.activeSubject?.name ||
        ""}`;
    },
    activeChannel() {
      return this.channelList.find(({ name }) => name == this.channel);
    },
    activeSubject() {
      return this.subjectList.find(({ name }) => name == this.subject);
    },
    tags: {
      get() {
        return ((this.group && this.group.tags) || []).map((tag) => ({
          text: tag
        }));
      },
      set: function(value) {
        if (this.group) {
          this.$set(this.group, "tags", value.map((tag) => this.$utils.trim(tag.text)));
        }
      }
    },
    tag_validation() {
      if (!this.tags || !this.tags.length) {
        return [];
      }
      var lst = [
        {
          classes: "min-length",
          rule: (tag) => tag.text.length < 7 // a@nn.cc
        },
        {
          classes: "avoid-item",
          rule: /^(?!Cannot).*$/,
          disableAdd: true
        },
        {
          classes: "no-braces",
          rule: ({ text }) =>
            text.indexOf("{") !== -1 || text.indexOf("}") !== -1
        }
      ];
      // if there is a tag_validation defined at group level use it
      if (
        this.group.tag_validation &&
        this.group.tag_validation.rule &&
        this.group.tag_validation.classes
      ) {
        var re_string = this.group.tag_validation.rule;
        var re = new RegExp(re_string);
        lst.push({
          classes: this.group.tag_validation.classes,
          rule: re
        });
      } else {
        if (this.defaultTagType) {
          // if no group tag_validation try to use the default one (if possible)
          // email
          if (this.defaultTagType.indexOf("email") >= 0) {
            lst.push({
              classes: "no-email",
              rule: /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/
            });
          }
          // number
          else if (this.defaultTagType.indexOf("number") >= 0) {
            lst.push({
              classes: "no-numbers",
              rule: /^([^0-9]*)$/
            });
          }
          // phone number
          else if (this.defaultTagType.indexOf("phone") >= 0) {
            lst.push({
              classes: "no-numbers",
              rule: /\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/
            });
          }
        }
      }
      return lst;
    },
    notification_mode_list() {
      let alarm_notification_modes =
        this.$root.config.references.alarm_notification_modes;

      return Object.keys(alarm_notification_modes).map((x) => ({
        id: parseInt(x),
        description: alarm_notification_modes[x]
      }))
    }
  },
  watch: {
    selection: {
      handler() {
        this.updateSelectedGroup();
      },
      immediate: true
    },
    group: {
      handler(group) {
        if (!group) return;
        for (var i in this.value) {
          if (
            this.value[i].channel == group.channel &&
            this.value[i].data_source == group.data_source
          ) {
            if (!isEqual(this.value[i], group)) {
              let groups = JSON.parse(JSON.stringify(this.value));
              groups[i] = group;
              this.$emit("input", groups);
              return;
            }
          }
        }
      },
      deep: true
    },
    value: {
      handler(n, o) {
        if (
          this.group == null &&
          this.channel &&
          this.subject &&
          n &&
          n.length
        ) {
          this.updateSelectedGroup();
        }
      },
      deep: true,
      immediate: true
    },
    notification_mode: {
      immediate: true,
      handler(n) {
        this.notification_mode_value = n;
      }
    },
    notification_mode_value(n) {
      this.$emit('notification_mode', n);
    }
  },
  methods: {
    can(channelName) {
      let group = this.activeSubject && this.getGroup(channelName, this.activeSubject.name);
      if (!group) return false;
      return group.rule
        ? (this.contract || {})[group.rule] || this.$can("manage", group.rule)
        : true;
    },
    getGroup(channelName, subjectName) {
      return (
        (this.value || []).find(
          ({ channel, data_source }) =>
            channel == channelName && data_source == subjectName
        ) || null
      );
    },
    updateSelectedGroup() {
      if (!this.activeChannel || !this.activeSubject) return;
      let group = this.getGroup(
        this.activeChannel.name,
        this.activeSubject.name
      );
      if (group != null) {
        this.group = JSON.parse(JSON.stringify(group));
        this.$nextTick(() => {
          if (this.$refs.inp) {
            var $el = null;
            for (var i in this.$refs.inp) {
              $el = $(this.$refs.inp[i].$el);
              if ($el.is(":visible")) {
                //$el.find("input").focus();
                break;
              }
            }
          }
        });
      }
    }
  },
  mounted() {
    this.menuCollapsed = this.filteredChannelList.length == 1;
  }
};
</script>

<style scoped>
.box-body {
  min-height: auto;
}
section {
  min-height: auto;
}

ul.nav-tabs > li.header {
  padding-top: 6px;
  margin-left: -10px;
  white-space: nowrap;
}

@media (min-width: 768px) {
  /* section {
    min-height: 250px;
  } */
  .box-body {
    /* min-height: 150px; */
  }
}

.nav-tabs-custom {
  margin-bottom: 5px;
}
.nav-pills > li.active > a,
.nav-pills > li.active > a:focus {
  color: inherit;
  background-color: inherit;
}

.tag-input {
  border: 1px solid #ccc;
  min-height: 120px;
  border-radius: 3px;
}
.clicable:hover {
  opacity: 0.8;
  cursor: pointer;
}
.menu-collapse:hover {
  color: orange;
}
</style>
<style>
.vue-tags-input {
  min-width: 100%;
}
.vue-tags-input .ti-input {
  border: 0 !important;
}
.vue-tags-input.ti-focus .ti-input {
  border: 0 !important;
  background: transparent;
}
.padding-10 {
  padding: 10px;
}
.bg-whitesmoke {
  background-color: whitesmoke;
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.box-title {
  font-size: 14pt;
  color: #666;
}
</style>
